import axios from 'axios';
import { makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION, TABLE_SORT_DIRECTION } from 'src/constants';
import { ENDPOINT, HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import {
  IRateCenter,
  IRateItem,
  IRatesForm,
  ISizingDelete,
  ISizingDetail,
  ISizingItem,
  ISizingStatus,
  ISizingToolCenter
} from 'src/constants/sizing-tool';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  BodyEntryDTO,
  BodyUpdateDTO,
  DeleteRateDTO,
  DeleteRecordDTO,
  FinalReportDTO,
  GetRateDetailDTO,
  GetSizingtDetailDTO,
  IRateListRequest,
  ISizingListRequest,
  ListRateTableDTO,
  ListRatesDTO,
  ListSizingtDTO,
  RateCreateDTO,
  RateScheduleDTO,
  RateScheduleUpdateDTO,
  RateUpdateDTO,
  SizingTool,
  SizingToolResponseDTO,
  StatusDTO
} from 'src/dto/sizing-tool.dto';
import { IHttpService } from 'src/services/http.service';
const DEFAULT_SORT_COLUMN = 'updateTime';

export interface ISizingTool {
  Sizer: SizingTool[];
  listSizingItem: ISizingItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortDirection: TABLE_SORT_DIRECTION;
  listRateItem: IRateItem[];
  totalPages_rate: number;
  totalRecords_rate: number;
  pageSize_rate: number;
  pageNumber_rate: number;
  sortBy_rate: string;
  sortDirection_rate: TABLE_SORT_DIRECTION;
  createEntry(body: BodyEntryDTO): Promise<ResponseDTO<SizingToolResponseDTO>>;
  updateEntry(body: BodyUpdateDTO): Promise<ResponseDTO<SizingToolResponseDTO>>;
  getFinalReport(body: any): Promise<ResponseDTO<SizingToolResponseDTO>>;
  createRateSchedule(
    body: RateScheduleDTO
  ): Promise<ResponseDTO<SizingToolResponseDTO>>;
  updateRateSchedule(
    body: RateScheduleUpdateDTO
  ): Promise<ResponseDTO<SizingToolResponseDTO>>;
  status(param: { id: string }): Promise<ResponseDTO<ISizingStatus>>;
  fetchList(request?: ISizingListRequest): Promise<void>;
  fetchListRate(request?: IRateListRequest): Promise<void>;
  getDetailRecord(param: { id: string }): Promise<ResponseDTO<ISizingDetail>>;
  getDetailRate(param: { id: string }): Promise<ResponseDTO<IRatesForm>>;
  getAllRates(): Promise<any>;
  DeleteRecord(param: { id: string }): Promise<ResponseDTO<ISizingDelete>>;
  DeleteRate(param: { id: string }): Promise<ResponseDTO<ISizingDelete>>;
  dispose(): void;
}

export class SizingToolStore implements ISizingTool {
  Sizer: SizingTool[] = [];
  listSizingItem: ISizingItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy = DEFAULT_SORT_COLUMN;
  sortDirection = TABLE_SORT_DIRECTION.DESC;
  listRateItem: IRateItem[] = [];
  totalPages_rate = 0;
  totalRecords_rate = 0;
  pageSize_rate = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber_rate = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy_rate = DEFAULT_SORT_COLUMN;
  sortDirection_rate = TABLE_SORT_DIRECTION.DESC;

  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      Sizer: observable.ref
    });
  }
  public async createEntry(body: BodyEntryDTO) {
    const formData = new FormData();

    const appendFormData = (key: string, value: any) => {
      if (value instanceof File) {
          formData.append(key, value);
      } else if (typeof value === 'object' && value !== null) {
          // Flatten the object and append each field separately
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
              appendFormData(`${key}[${nestedKey}]`, nestedValue);
          });
      } else {
          formData.append(key, String(value));
      }
  };

  Object.entries(body).forEach(([key, value]) => {
      if (typeof value === 'undefined') return;

      if (Array.isArray(value)) {
          if (value.length !== 0) {
              value.forEach((item, index) => {
                  if (item instanceof File) {
                      formData.append(`${key}[]`, item);
                  } else if (typeof item === 'object' && item !== null) {
                      // If item is an object, flatten it and append each field
                      Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                          appendFormData(`${key}[${index}][${nestedKey}]`, nestedValue);
                      });
                  } else {
                      appendFormData(`${key}[${index}]`, item);
                  }
              });
          }
      } else {
          appendFormData(key, value);
      }
  });

    const res: ResponseDTO<SizingToolResponseDTO> = await this.http.uploadFile(
      ENDPOINT.SIZING_ENTRY_CREATE,
      formData,
      axios.CancelToken.source().token
    );

    return res;
  }
  public async fetchList(request: ISizingListRequest): Promise<void> {
    const requestDTO = new ListSizingtDTO(request);
    const res: ResponseDTO<ISizingToolCenter> = await this.http.request<
      ListSizingtDTO,
      ISizingToolCenter
    >(requestDTO);

    if (res.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      const listPlantApplication = res.data;
      runInAction(() => {
        this.listSizingItem = listPlantApplication?.paginatedResults || [];
        this.totalPages = listPlantApplication?.total || 0;

        this.totalRecords = listPlantApplication?.total || 0;

        this.pageSize =
          listPlantApplication?.limit ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber =
          listPlantApplication?.page || PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  public async getDetailRecord(query: { id: string }) {
    const getSizingDetailDTO = new GetSizingtDetailDTO(query);
    const res: ResponseDTO<ISizingDetail> = await this.http.request(
      getSizingDetailDTO
    );
    return res;
  }

  public async DeleteRecord(query: { id: string }) {
    const deleteDTO = new DeleteRecordDTO(query);
    const res: ResponseDTO<ISizingDelete> = await this.http.request(deleteDTO);
    return res;
  }

  public async status(query: { id: string }) {
    const statusDTO = new StatusDTO(query);
    const res: ResponseDTO<ISizingStatus> = await this.http.request(statusDTO);
    return res;
  }

  public async DeleteRate(query: { id: string }) {
    const deleteDTO = new DeleteRateDTO(query);
    const res: ResponseDTO<ISizingDelete> = await this.http.request(deleteDTO);
    return res;
  }

  public async updateEntry(body: BodyUpdateDTO) {
    const formData = new FormData();

    const appendFormData = (key: string, value: any) => {
        if (value instanceof File) {
            formData.append(key, value);
        } else if (typeof value === 'object' && value !== null) {
            // Flatten the object and append each field separately
            Object.entries(value).forEach(([nestedKey, nestedValue]) => {
                appendFormData(`${key}[${nestedKey}]`, nestedValue);
            });
        } else {
            formData.append(key, String(value));
        }
    };

    Object.entries(body).forEach(([key, value]) => {
        if (typeof value === 'undefined') return;

        if (Array.isArray(value)) {
            if (value.length !== 0) {
                value.forEach((item, index) => {
                    if (item instanceof File) {
                        formData.append(`${key}[]`, item);
                    } else if (typeof item === 'object' && item !== null) {
                        // If item is an object, flatten it and append each field
                        Object.entries(item).forEach(([nestedKey, nestedValue]) => {
                            appendFormData(`${key}[${index}][${nestedKey}]`, nestedValue);
                        });
                    } else {
                        appendFormData(`${key}[${index}]`, item);
                    }
                });
            }
        } else {
            appendFormData(key, value);
        }
    });

    const res: ResponseDTO<SizingToolResponseDTO> = await this.http.uploadFile(
        ENDPOINT.SIZING_ENTRY_UPDATE,
        formData,
        axios.CancelToken.source().token
    );

    return res;
}

  public async createRateSchedule(body: RateScheduleDTO) {
    const rateDTO = new RateCreateDTO(body);
    const res: ResponseDTO<SizingToolResponseDTO> = await this.http.request(
      rateDTO
    );

    return res;
  }

  public async getFinalReport(body: any) {
    const reportDTO = new FinalReportDTO(body);
    const res: ResponseDTO<SizingToolResponseDTO> = await this.http.request(
      reportDTO
    );

    return res;
  }
  public async getAllRates(): Promise<any> {
    const requestDTO = new ListRatesDTO();
    const res: ResponseDTO<any> = await this.http.request(requestDTO);
    if (res.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      return res.data;
    }
  }

  public async getDetailRate(query: { id: string }) {
    const getRateDetailDTO = new GetRateDetailDTO(query);
    const res: ResponseDTO<IRatesForm> = await this.http.request(
      getRateDetailDTO
    );
    return res;
  }

  public async updateRateSchedule(body: RateScheduleUpdateDTO) {
    const rateDTO = new RateUpdateDTO(body);
    const res: ResponseDTO<SizingToolResponseDTO> = await this.http.request(
      rateDTO
    );

    return res;
  }

  public async fetchListRate(request: IRateListRequest): Promise<void> {
    const requestDTO = new ListRateTableDTO(request);
    const res: ResponseDTO<IRateCenter> = await this.http.request<
      ListRateTableDTO,
      IRateCenter
    >(requestDTO);

    if (res.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      const listPlantApplication = res.data;
      runInAction(() => {
        this.listRateItem = listPlantApplication?.paginatedResults || [];
        this.totalPages_rate = listPlantApplication?.total || 0;

        this.totalRecords_rate = listPlantApplication?.total || 0;

        this.pageSize_rate =
          listPlantApplication?.limit ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber_rate =
          listPlantApplication?.page || PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  dispose(): void {
    throw new Error('Method not implemented.');
  }
}
