import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { TABLE_SORT_DIRECTION } from 'src/constants';

export interface SizingTool {
  _id?: string;
  time_of_use?: {
    rate?: number;
    time?: string;
  }[];
  demand_charge?: {
    rate?: number;
    time?: string;
  }[];
  is_zero_export?: boolean;
  cd_price?: number;
  cd_installation?: number;
  cd_incentive?: number;
  cd_total_cost?: number;
}

export interface RateScheduleDTO {
  utility_name?: string;
  utility_location?: string;
  markerLocation_lat?: number;
  markerLocation_lng?: number;
  rate_schedule_name?: string;
  seasons?: any;
  trackExports?: boolean;
  demand_charge_checkbox?: boolean;
  export_rate_matrix?: any;
  energy_rate_matrix?: any;
  demand_rate_matrix?: any;
  demand_charges_checkbox?: boolean;
}

export interface MarkerLocation {
  address: string;
  lat: number;
  lng: number;
}

export interface RateScheduleUpdateDTO {
  _id?: string;
  utility_name?: string;
  utility_location?: string;
  markerLocation_lat?: number;
  markerLocation_lng?: number;
  rate_schedule_name?: string;
  seasons?: any;
  demand_charge_checkbox?: boolean;
  energy_rate_matrix?: any;
  export_rate_matrix?: any;
  demand_rate_matrix?: any;
  trackExports?: boolean;
  demand_charges_checkbox?: boolean;
}


export interface BodyEntryDTO {
  deployment_name?: string;
  deployment_location?: string;
  is_zero_export?: boolean;
  charge_from_grid?: boolean;
  markerLocation_lat?: number;
  markerLocation_lng?: number;
  reserve_checkbox?: boolean;
  reserve_level?: number;
  cd_price?: number;
  rate_data?: any;
  cd_installation?: number;
  cd_incentive?: any;
  cd_properties?: any;
  cd_total_cost?: number;
  solar_profile?: File[];
  load_profile?: File[];
}

export interface BodyUpdateDTO {
  _id?: string;
  deployment_name?: string;
  deployment_location?: string;
  is_zero_export?: boolean;
  charge_from_grid?: boolean;
  cd_price?: number;
  reserve_checkbox?: boolean;
  reserve_level?: number;
  markerLocation_lat?: number;
  markerLocation_lng?: number;
  cd_installation?: number;
  rate_data?: any;
  cd_incentive?: any;
  cd_properties?: any;
  cd_total_cost?: number;
  solar_profile?: File[];
  load_profile?: File[];
}

export interface ISizingListRequest {
  q?: string;
  status?: string;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
}

export interface IRateListRequest {
  q?: string;
  status?: string;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
}

export interface SizingToolResponseDTO {
  data: string;
}

export class ListSizingtDTO extends DTO {
  public param: object | undefined;
  public query: ISizingListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: ISizingListRequest) {
    super();
    this.query = request;
  }
}

export class ListRateTableDTO extends DTO {
  public param: object | undefined;
  public query: IRateListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_RATE_TABLE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IRateListRequest) {
    super();
    this.query = request;
  }
}
export class GetSizingtDetailDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_GET;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
export class DeleteRecordDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_DELETE;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}


export class StatusDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_STATUS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class DeleteRateDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_DELETE_RATE;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class RateCreateDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.SIZING_TOOL_CREATE_RATE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: RateScheduleDTO) {
    super();
    this.body = body;
  }
}

export class FinalReportDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.SIZING_TOOL_FINAL_REPORT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: any) {
    super();
    this.body = body;
  }
}

export class RateUpdateDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.SIZING_TOOL_UPDATE_RATE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: RateScheduleDTO) {
    super();
    this.body = body;
  }
}

export class ListRatesDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_LIST_RATE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
  }
}

export class GetRateDetailDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.SIZING_TOOL_GET_RATE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}